import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material';
import { List } from 'immutable';
import { Winery } from '../../core';
import { AuthService } from '../../core';
import { Constants, Utils, ProducerOp } from '../../scripts';
import { ButtonSpec } from '../../shared/content-card';
import { NewWineryDialogComponent } from '../new-winery-dialog/new-winery-dialog.component';

@Component({
  selector: 'app-producer-list',
  templateUrl: './producer-list.component.html',
  styleUrls: ['./producer-list.component.scss'],
})
export class ProducerListComponent implements OnInit, OnChanges {
  constructor(private dialog: MatDialog, public auth: AuthService) {}

  @Input() producers: List<Winery>;
  @Input() rawProducers: List<Winery>;
  @Input() filter: string;
  @Input() category: string;
  @Output() action = new EventEmitter<[ProducerOp, any]>();

  counts: {
    active: 0;
    inactive: 0;
    all: 0;
  };

  producerFilter = Constants.WineFilters[0];
  filters = Constants.WineFilters;
  buttons: ButtonSpec[];
  filterString: string;

  ngOnInit() {
    this.buttons = [{ name: 'edit', icon: 'edit', tip: `Edit ${this.category} details` }];
  }

  ngOnChanges(): void {
    this.producerFilter = Constants.WineFilters.find(f => f.value === this.filter);
    if (this.rawProducers) {
      this.counts = { active: 0, inactive: 0, all: 0 };
      this.rawProducers.forEach(p => {
        this.counts.all++;
        if (p.inactive) {
          this.counts.inactive++;
        } else {
          this.counts.active++;
        }
      });
    }
  }

  applyFilter() {
    this.action.emit([ProducerOp.FilterProducers, this.filter]);
  }

  async producerAction(producer: Winery, button) {
    switch (button) {
      case 'edit':
        await this.editProducer(producer);
        break;
    }
  }

  editProducer(producer: Winery) {
    this.action.emit([ProducerOp.EditProducer, producer]);
  }

  addProducer() {
    const dialogRef = this.dialog.open(NewWineryDialogComponent, {
      width: '500px',
      data: {
        category: this.category,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        result.category = this.category;
      }
      this.action.emit([ProducerOp.AddProducer, result]);
    });
  }

  subtitle(producer: Winery): string {
    if (!producer || !producer.contact || !producer.contact.address) {
      return '';
    }
    const addr = producer.contact.address;
    return addr.locality ? `${addr.locality}, ${addr.region || ''}` : `${addr.region || ''} ${addr.country || ''}`;
  }

  wineryImage(producer: Winery): string {
    return Utils.containerImg(producer);
  }

  filterProducers = (producer: Winery) => {
    if (!this.filterString || this.filterString.trim().length === 0) {
      return true;
    }
    return producer.name.toLowerCase().includes(this.filterString.toLowerCase());
  };
}
