import { BehaviorSubject, Subject } from 'rxjs';
import { Event, EventDetail } from '../models';
import { List } from 'immutable';
import { ApiService } from './api.service';
import { finalize } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
export class EventsService {
    constructor(apiService) {
        this.apiService = apiService;
        this._events$ = new BehaviorSubject(List([]));
        this._fetchingEvents = false;
        this._fetched = false;
        this._lastError = new Subject();
        this.lastError$ = this._lastError.asObservable();
    }
    get events$() {
        if (!this._fetchingEvents && !this._fetched) {
            this._fetchingEvents = true;
            this.apiService
                .get('/events?past=true')
                .pipe(finalize(() => {
                this._fetchingEvents = false;
                this._fetched = true;
            }))
                .subscribe(events => {
                this._events$.next(List(events));
            }, error => {
                this.setLastError('Retrieving events', error.message);
            });
        }
        return this._events$.asObservable();
    }
    setLastError(context, message, event) {
        this._lastError.next({
            context: context,
            message: message,
            event: event,
            formatted: `${context} failed because ${message}`,
        });
    }
    updateEventInList(event) {
        const _events = this._events$.getValue();
        const index = _events.findIndex(w => w._id === event._id);
        this._events$.next(_events.set(index, event));
    }
    addEvent(event) {
        const returnedEvent = new BehaviorSubject(event);
        this.apiService.post('/events', event).subscribe(newEvent => {
            returnedEvent.next(newEvent);
            this._events$.next(this._events$.getValue().insert(0, newEvent));
        }, error => {
            this.setLastError('Adding Event', error.message, event);
        });
        return returnedEvent.asObservable();
    }
    deleteEvent(event) {
        const returnedEvent = new BehaviorSubject(event);
        const events = this._events$.getValue();
        const index = events.findIndex(s => s._id === event._id);
        if (index === -1) {
            throw this.setLastError('Deleting Event', 'event not in list', event);
        }
        else {
            this.apiService.delete(`/events/${event._id}`).subscribe(() => {
                returnedEvent.next({});
                this._events$.next(events.splice(index, 1));
            }, error => {
                this.setLastError('Deleting event', error.message, event);
            });
        }
        return returnedEvent.asObservable();
    }
    fetchEvent(eventId) {
        const fetchedEvent$ = new BehaviorSubject({});
        const events = this._events$.getValue();
        if (events.find(w => w._id === eventId)) {
            this.apiService.get(`/events/${eventId}`).subscribe(eventDetail => {
                fetchedEvent$.next(eventDetail);
            }, error => {
                this.setLastError('Fetching Event', error.message);
            });
        }
        else {
            this.setLastError('Fetching Event', `invalid id supplied`);
        }
        return fetchedEvent$.asObservable();
    }
    saveEventDetail(event) {
        const _events = this._events$.getValue();
        console.log(event);
        const index = _events.findIndex(s => s._id === event._id);
        const event$ = new BehaviorSubject(_events.get(index));
        if (index === -1) {
            this.setLastError('Saving Event', 'event not in list', event);
        }
        else {
            this.apiService.put(`/events/${event._id}`, event).subscribe(savedEvent => {
                this.updateEventInList(savedEvent);
                event$.next(savedEvent);
            }, error => {
                this.setLastError('Saving Event', error.message, event);
            });
        }
        return event$.asObservable();
    }
    addEventProduct(event, product) {
        const event$ = new BehaviorSubject(event);
        this.apiService.post(`/events/${event._id}/products`, product).subscribe(savedEvent => {
            this.updateEventInList(savedEvent);
            event$.next(savedEvent);
        });
        return event$.asObservable();
    }
    removeEventProduct(event, productId) {
        const event$ = new BehaviorSubject(event);
        this.apiService.delete(`/events/${event._id}/products/${productId}`).subscribe(savedEvent => {
            this.updateEventInList(savedEvent);
            event$.next(savedEvent);
        });
        return event$.asObservable();
    }
    updateEventProduct(event, product) {
        const event$ = new BehaviorSubject(event);
        this.apiService.put(`/events/${event._id}/products/${product._id}`, product).subscribe(savedEvent => {
            this.updateEventInList(savedEvent);
            event$.next(savedEvent);
        });
        return event$.asObservable();
    }
}
EventsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EventsService_Factory() { return new EventsService(i0.ɵɵinject(i1.ApiService)); }, token: EventsService, providedIn: "root" });
