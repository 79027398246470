<script src="../../home/home.module.ts"></script>
<mat-toolbar color="primary" fxLayout="row">
  <span class="header-brand" fxFlex="240px">
    <span fxLayout="column">
      <span *ngIf="auth.loggedIn">
        <span *ngIf="(wineryStore.settings$ | async).appName as appName">
          <span *ngIf="!appName.toLowerCase().startsWith('bottlevin')" class="header-app-name">{{ appName }} by</span>
        </span>
      </span>
      <a id="main-brand" routerLink="/"><img class="img-fluid" src="/assets/bottlevin_logo.png" alt="bottleVin" /> </a>
    </span>
  </span>
  <span fxFlex> </span>
  <span fxFlex="nogrow">
    <span *ngIf="auth.loggingIn">
      <a mat-button>Logging in...</a>
    </span>
    <ng-template [ngIf]="!auth.loggingIn">
      <a mat-button *ngIf="!auth.loggedIn" (click)="auth.login()"><span>Log In</span></a>
      <span *ngIf="auth.loggedIn && auth.userProfile">
        <span fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
          <span fxFlex="15%"
            ><img *ngIf="auth.userProfile.picture" [src]="auth.userProfile.picture" alt="picture" class="profile-pic"
          /></span>
          <a mat-button [matMenuTriggerFor]="user" fxFlex="85%">{{ displayName }}</a>
          <mat-menu class="profile-menu" #user="matMenu">
            <a mat-menu-item [routerLink]="'/settings'">Settings</a>
            <a
              *ngIf="auth.isAdmin() || auth.appAdmin()"
              [disabled]="!auth.hasPerm(Perms.NOTIFICATIONS)"
              mat-menu-item
              [routerLink]="'/notifications'"
              >Notifications</a
            >
            <a *ngIf="auth.isAdmin() || auth.appAdmin()" [disabled]="!auth.hasPerm(Perms.STORIES)" mat-menu-item [routerLink]="'/stories'"
              >Stories</a
            >
            <a *ngIf="auth.isAdmin()" [disabled]="!auth.hasPerm(Perms.IMPORT)" mat-menu-item [routerLink]="'/import'">Import Content</a>
            <a *ngIf="auth.isAdmin()" [disabled]="!auth.hasPerm(Perms.SYNC)" mat-menu-item [routerLink]="'/sync'">Sync to Production</a>
            <a *ngIf="auth.isAdmin()" [disabled]="!auth.hasPerm(Perms.USERS)" mat-menu-item [routerLink]="'/users'">Manage Users</a>
            <button mat-menu-item (click)="logout()">Logout</button>
          </mat-menu>
        </span>
      </span>
    </ng-template>
  </span>
</mat-toolbar>
