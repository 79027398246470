import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { List } from 'immutable';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { Merchant, MerchantDetail, Promo, WineTasting, Product, Winery } from '../models';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';

export interface MerchantError {
  context: string;
  message: string;
  merchant?: Merchant;
  formatted: string;
}

@Injectable({
  providedIn: 'root',
})
export class MerchantStoreService {
  private _merchants$: BehaviorSubject<List<Merchant>> = new BehaviorSubject(List([]));
  private _fetchingMerchants = false;
  private _fetched = false;
  private _fetchingAll = false;
  private _allFetched = false;

  private _lastError: Subject<MerchantError> = new Subject<MerchantError>();
  public lastError$: Observable<MerchantError> = this._lastError.asObservable();

  private _merchantFilter = JSON.parse(localStorage.getItem('wineryFilter')) || 'all';
  public get merchantFilter() {
    return this._merchantFilter;
  }
  public set merchantFilter(filterValue: string) {
    this._merchantFilter = filterValue;
    this._merchants$.next(this._merchants$.value);
    localStorage.setItem('wineryFilter', JSON.stringify(filterValue));
  }

  constructor(private apiService: ApiService, private auth: AuthService) {}

  get merchantsRaw$() {
    if (!this._fetchingMerchants && !this._fetched) {
      this._fetchingMerchants = true;
      this.apiService
        .get('/merchants')
        .pipe(
          finalize(() => {
            this._fetchingMerchants = false;
            this._fetched = true;
          })
        )
        .subscribe(
          merchants => {
            this._merchants$.next(List(merchants));
          },
          error => {
            this.setLastError('Retrieving merchants', error.message);
          }
        );
    }

    return this._merchants$.asObservable();
  }

  get merchantsAll$() {
    if (!this._fetchingAll && !this._allFetched) {
      let params: HttpParams;
      if (this.auth.isAdmin()) {
        params = new HttpParams().set('details', 'true');
      }
      this._fetchingAll = true;
      this.apiService
        .get('/merchants', params)
        .pipe(
          finalize(() => {
            this._fetchingAll = false;
            this._allFetched = true;
          })
        )
        .subscribe(
          merchants => {
            if (this.auth.isAdmin()) {
              merchants = [this._allMerchant, ...merchants];
            }
            this._merchants$.next(List(merchants));
          },
          error => {
            this.setLastError('Retrieving merchants', error.message);
          }
        );
    }

    return this._merchants$.asObservable();
  }

  // This list may be filtered
  public merchants$: Observable<List<Merchant>> = this.merchantsRaw$.pipe(
    map(merchants =>
      merchants.filter(m => {
        switch (this._merchantFilter) {
          case 'active':
            return !m.inactive;
          case 'inactive':
            return m.inactive;
          default:
            return true;
        }
      })
    )
  );

  private readonly _AllMerchantID = '__ALL__';
  // This is placeholder entry for selected all wineries within the list (only available to Admin)
  private _allMerchant: Winery = {
    _id: this._AllMerchantID,
    name: 'All Merchants',
    contact: {
      address: {
        street_address: '1171 Homestead Rd',
        locality: 'Santa Clara',
        region: 'CA',
        postal_code: '95050',
        country: 'USA',
      },
      email: 'admin@bottlevin.com',
      phone: '',
      website: 'https://www.bottlevin.com',
    },
  };

  isAll(merchant): boolean {
    return merchant._id === this._AllMerchantID;
  }

  public fetchMerchant(merchantId: string, force: boolean = false): Observable<MerchantDetail> {
    const merchant$: BehaviorSubject<MerchantDetail> = new BehaviorSubject<MerchantDetail>({} as MerchantDetail);
    const _merchants = this._merchants$.getValue();
    const _merchant = _merchants.find(m => m._id === merchantId);
    if (_merchant) {
      const merchantDetail = _merchant as MerchantDetail;
      if (!force && Array.isArray(merchantDetail.admins)) {
        console.log(`Returning cached merchant for ${merchantId}`);
        merchant$.next(merchantDetail);
      } else {
        console.log(`Retrieving merchant ${merchantId}`);
        this.apiService.get(`/merchants/${merchantId}`).subscribe(
          fetchedMerchant => {
            this.updateMerchantInList(fetchedMerchant);
            merchant$.next(fetchedMerchant);
          },
          error => {
            this.setLastError(`Retreiving merchant ${merchantId}`, error.message);
          }
        );
      }
    } else {
      this.setLastError(`Retreiving merchant`, `merchant with id ${merchantId} not in list`);
    }
    return merchant$.asObservable();
  }

  public addMerchant(merchant: Merchant): Observable<MerchantDetail> {
    const merchant$: BehaviorSubject<MerchantDetail> = new BehaviorSubject<MerchantDetail>({} as MerchantDetail);
    this.apiService.post('/merchants', merchant).subscribe(newMerchant => {
      this._merchants$.next(this._merchants$.getValue().push(newMerchant));
      merchant$.next(newMerchant);
    });

    return merchant$.asObservable();
  }

  public saveMerchant(merchant: MerchantDetail): Observable<MerchantDetail> {
    const merchant$: BehaviorSubject<MerchantDetail> = new BehaviorSubject<MerchantDetail>(merchant);
    this.apiService.put(`/merchants/${merchant._id}`, merchant).subscribe(savedMerchant => {
      this.updateMerchantInList(savedMerchant);
      merchant$.next(savedMerchant);
    });
    return merchant$.asObservable();
  }

  public removeMerchant(merchant: MerchantDetail): Observable<MerchantDetail> {
    const merchant$: BehaviorSubject<MerchantDetail> = new BehaviorSubject<MerchantDetail>(merchant);
    this.apiService.delete(`/merchants/${merchant._id}`).subscribe(() => {
      const _merchants = this._merchants$.getValue();
      const index = _merchants.findIndex(m => m._id === merchant._id);
      this._merchants$.next(_merchants.remove(index));
      merchant$.next({} as MerchantDetail);
    });
    return merchant$.asObservable();
  }

  addProduct(merchant: MerchantDetail, product: any) {
    const merchant$: BehaviorSubject<MerchantDetail> = new BehaviorSubject<MerchantDetail>(merchant);
    this.apiService.post(`/merchants/${merchant._id}/products`, product).subscribe(savedMerchant => {
      this.updateMerchantInList(savedMerchant);
      merchant$.next(savedMerchant);
    });
    return merchant$.asObservable();
  }

  updateProduct(merchant: MerchantDetail, product: any) {
    const merchant$: BehaviorSubject<MerchantDetail> = new BehaviorSubject<MerchantDetail>(merchant);
    this.apiService.put(`/merchants/${merchant._id}/products/${product._id}`, product).subscribe(savedMerchant => {
      this.updateMerchantInList(savedMerchant);
      merchant$.next(savedMerchant);
    });
    return merchant$.asObservable();
  }

  removeProduct(merchant: MerchantDetail, productId: string) {
    const merchant$: BehaviorSubject<MerchantDetail> = new BehaviorSubject<MerchantDetail>(merchant);
    this.apiService.delete(`/merchants/${merchant._id}/products/${productId}`).subscribe(savedMerchant => {
      this.updateMerchantInList(savedMerchant);
      merchant$.next(savedMerchant);
    });
    return merchant$.asObservable();
  }

  private setLastError(context: string, message: string, merchant?: Merchant, detail?: Product | WineTasting) {
    this._lastError.next({
      context,
      message,
      merchant,
      detail,
      formatted: `${context} failed because ${message}`,
    } as MerchantError);
  }

  private updateMerchantInList(merchant: Merchant) {
    const _merchants = this._merchants$.getValue();
    const index = _merchants.findIndex(w => w._id === merchant._id);
    this._merchants$.next(_merchants.set(index, merchant));
  }

  addPromo(merchant: MerchantDetail, promo: Promo) {
    const merchant$: BehaviorSubject<MerchantDetail> = new BehaviorSubject<MerchantDetail>(merchant);
    this.apiService.post(`/merchants/${merchant._id}/promos`, promo).subscribe(savedMerchant => {
      this.updateMerchantInList(savedMerchant);
      merchant$.next(savedMerchant);
    });
    return merchant$.asObservable();
  }

  removePromo(merchant: MerchantDetail, promo: Promo) {
    const merchant$: BehaviorSubject<MerchantDetail> = new BehaviorSubject<MerchantDetail>(merchant);
    this.apiService.delete(`/merchants/${merchant._id}/promos/${promo._id}`).subscribe(savedMerchant => {
      this.updateMerchantInList(savedMerchant);
      merchant$.next(savedMerchant);
    });
    return merchant$.asObservable();
  }

  updatePromo(merchant: MerchantDetail, promo: Promo) {
    const merchant$: BehaviorSubject<MerchantDetail> = new BehaviorSubject<MerchantDetail>(merchant);
    this.apiService.put(`/merchants/${merchant._id}/promos/${promo._id}`, promo).subscribe(savedMerchant => {
      this.updateMerchantInList(savedMerchant);
      merchant$.next(savedMerchant);
    });
    return merchant$.asObservable();
  }

  addTasting(merchant: MerchantDetail, tasting: WineTasting): Observable<MerchantDetail> {
    const merchant$: BehaviorSubject<MerchantDetail> = new BehaviorSubject<MerchantDetail>(merchant);
    this.apiService.post(`/merchants/${merchant._id}/tastings`, tasting).subscribe(
      savedMerchant => {
        this.updateMerchantInList(savedMerchant);
        merchant$.next(savedMerchant);
      },
      error => {
        this.setLastError('Adding tasting', error.message, merchant, tasting);
      }
    );

    return merchant$.asObservable();
  }

  removeTastingByIndex(merchant: MerchantDetail, index: number): Observable<MerchantDetail> {
    const tasting = merchant.menu[index];
    const merchant$: BehaviorSubject<MerchantDetail> = new BehaviorSubject<MerchantDetail>(merchant);
    this.apiService.delete(`/merchants/${merchant._id}/tastings/${tasting._id}`).subscribe(
      updatedWinery => {
        this.updateMerchantInList(updatedWinery);
        merchant$.next(updatedWinery);
      },
      error => {
        this.setLastError('Removing tasting', error.message, merchant, tasting);
      }
    );

    return merchant$.asObservable();
  }

  fetchTastingByIndex(merchant: Merchant, index: number): Observable<WineTasting> {
    const fetchedTasting: BehaviorSubject<WineTasting> = new BehaviorSubject({} as WineTasting);
    if (merchant.menu.length > index) {
      const tasting = merchant.menu[index];
      this.apiService.get(`/merchants/${merchant._id}/tastings/${tasting._id}`).subscribe(
        wineTasting => {
          fetchedTasting.next(wineTasting);
        },
        error => {
          this.setLastError('Fetching tasting', error.message, merchant, tasting);
        }
      );
    } else {
      this.setLastError('Fetching tasting', `invalid index ${index} supplied`, merchant);
    }
    return fetchedTasting.asObservable();
  }

  saveTasting(merchant: Merchant, tasting: WineTasting): Observable<WineTasting> {
    const savedTasting: BehaviorSubject<WineTasting> = new BehaviorSubject({} as WineTasting);
    this.apiService.put(`/merchants/${merchant._id}/tastings/${tasting._id}`, tasting).subscribe(
      tastingDetail => {
        const tindex = merchant.menu.findIndex(t => t._id === tasting._id);
        merchant.menu[tindex] = tastingDetail;
        this.updateMerchantInList(merchant);
        savedTasting.next(tastingDetail);
      },
      error => {
        this.setLastError('Saving Tasting', error.message, merchant, tasting);
      }
    );
    return savedTasting.asObservable();
  }
}
