import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, flatMap, map, switchMap } from 'rxjs/operators';
import { AuthService, Merchant, MerchantDetail, Winery, WineryStoreService } from '../../core';
import { NavigationEnd, RouteConfigLoadEnd, Router } from '@angular/router';
import { MerchantStoreService } from '../../core/services/merchant-store.service';
import { Constants } from '../../scripts/constants';

@Component({
  selector: 'app-layout-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  constructor(
    public wineryStore: WineryStoreService,
    public merchantStore: MerchantStoreService,
    public router: Router,
    public auth: AuthService
  ) {}

  Perms = Constants.Perms;
  ReportTypes = Constants.ReportTypes;
  selectedWinery$: Observable<Winery>;
  selectedMerchant$: Observable<Merchant>;

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd || event instanceof RouteConfigLoadEnd) {
        this.selectedWinery$ = undefined;
        this.selectedMerchant$ = undefined;
        if (this.router.url.startsWith('/cms/wineries')) {
          const components = this.router.url.split('/');
          const wineryId = components[3];
          this.selectedWinery$ = this.wineryStore.wineFilter$.pipe(
            switchMap(f =>
              this.wineryStore.wineries$.pipe(
                filter(w => w.size > 0),
                flatMap(wineries => this.wineryStore.fetchWinery(wineryId).pipe(map(winery => this.wineryStore.filteredWinery(winery, f))))
              )
            )
          );
        } else if (this.router.url.startsWith('/cms/merchants')) {
          const components = this.router.url.split('/');
          const merchantId = components[3];
          this.selectedMerchant$ = this.merchantStore.merchants$.pipe(
            filter(m => m.size > 0),
            map(merchants => merchants.find(w => w._id === merchantId))
          );
        }
      }
    });
  }
}
