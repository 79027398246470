var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { EventEmitter, OnChanges, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { List } from 'immutable';
import { AuthService } from '../../core';
import { Constants, Utils, ProducerOp } from '../../scripts';
import { NewWineryDialogComponent } from '../new-winery-dialog/new-winery-dialog.component';
export class ProducerListComponent {
    constructor(dialog, auth) {
        this.dialog = dialog;
        this.auth = auth;
        this.action = new EventEmitter();
        this.producerFilter = Constants.WineFilters[0];
        this.filters = Constants.WineFilters;
        this.filterProducers = (producer) => {
            if (!this.filterString || this.filterString.trim().length === 0) {
                return true;
            }
            return producer.name.toLowerCase().includes(this.filterString.toLowerCase());
        };
    }
    ngOnInit() {
        this.buttons = [{ name: 'edit', icon: 'edit', tip: `Edit ${this.category} details` }];
    }
    ngOnChanges() {
        this.producerFilter = Constants.WineFilters.find(f => f.value === this.filter);
        if (this.rawProducers) {
            this.counts = { active: 0, inactive: 0, all: 0 };
            this.rawProducers.forEach(p => {
                this.counts.all++;
                if (p.inactive) {
                    this.counts.inactive++;
                }
                else {
                    this.counts.active++;
                }
            });
        }
    }
    applyFilter() {
        this.action.emit([ProducerOp.FilterProducers, this.filter]);
    }
    producerAction(producer, button) {
        return __awaiter(this, void 0, void 0, function* () {
            switch (button) {
                case 'edit':
                    yield this.editProducer(producer);
                    break;
            }
        });
    }
    editProducer(producer) {
        this.action.emit([ProducerOp.EditProducer, producer]);
    }
    addProducer() {
        const dialogRef = this.dialog.open(NewWineryDialogComponent, {
            width: '500px',
            data: {
                category: this.category,
            },
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                result.category = this.category;
            }
            this.action.emit([ProducerOp.AddProducer, result]);
        });
    }
    subtitle(producer) {
        if (!producer || !producer.contact || !producer.contact.address) {
            return '';
        }
        const addr = producer.contact.address;
        return addr.locality ? `${addr.locality}, ${addr.region || ''}` : `${addr.region || ''} ${addr.country || ''}`;
    }
    wineryImage(producer) {
        return Utils.containerImg(producer);
    }
}
