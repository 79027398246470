import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';
import { environment } from '../../environments/environment';
import { Wine, Winery, Merchant } from '../core';
import { Constants } from './constants';

export class Utils {
  /**
   * Retrieve all the dirty values from a form in the appropriate nested structure
   * @param form
   */
  static getDirtyValues(form: any) {
    let dirtyValues: any;

    if (Array.isArray(form.controls)) {
      if (form.dirty) {
        return form.value;
      }
      return [];
    } else {
      dirtyValues = {};
    }
    Object.keys(form.controls).forEach(key => {
      const currentControl = form.controls[key];

      if (currentControl.dirty) {
        if (currentControl.controls) {
          dirtyValues[key] = this.getDirtyValues(currentControl);
        } else {
          dirtyValues[key] = currentControl.value;
        }
      }
    });

    return dirtyValues;
  }

  /**
   * Update all form values with new data unless they are currently edited (dirty)
   * For FormArray entries it will only update existing entries. New entries must be
   * added outside of this function
   * @param form
   * @param data
   */
  static updateNonDirty(form: any, data: any) {
    Object.keys(form.controls).forEach(key => {
      const currentControl = form.controls[key];
      const newValue = data[key];

      if (currentControl.controls) {
        if (currentControl instanceof FormArray) {
          this.updateNonDirty(currentControl, newValue || []);
          // Set the array itself as non-dirty
          if (currentControl.controls.length === newValue.length) {
            // If the form array has the same number of entries as the data then make sure the dirty value is based on the elements
            // otherwise just leave it as is because there's a pending change of add or delete
            if (!currentControl.controls.reduce((dirty, c) => dirty || c.dirty, false)) {
              currentControl.markAsPristine();
            }
          }
        } else {
          this.updateNonDirty(currentControl, newValue || {});
        }
      } else {
        if (!currentControl.dirty) {
          // Control wasn't dirty so just update it with the new value if there is one
          if (newValue !== undefined) {
            currentControl.setValue(newValue);
            currentControl.markAsPristine();
          }
        } else {
          const oldValue = currentControl.value;
          // If the control value and the new value are the same or the control is empty and the new value undefined then mark as pristine
          // NOTE: We also update the value to the new value even though its the same as this will emit a valueChanges event
          if (
            oldValue === newValue ||
            (oldValue instanceof Date && oldValue.toISOString() === newValue) ||
            ((newValue === undefined && oldValue == null) || oldValue === '')
          ) {
            currentControl.setValue(newValue);
            currentControl.markAsPristine();
          } else if (
            Array.isArray(newValue) &&
            Array.isArray(oldValue) &&
            newValue.length === oldValue.length &&
            oldValue.every((val, i) => val === newValue[i])
          ) {
            currentControl.setValue(newValue);
            currentControl.markAsPristine();
          }
        }
      }
    });
  }

  static notEmpty(control: FormControl) {
    const isValid = (control.value || '').trim().length > 0;
    return isValid ? null : { empty: true };
  }

  static dobToAgeGroup(dob: string): string {
    if (dob) {
      const age = moment().diff(dob, 'years');
      if (age) {
        const ageGroup = Constants.AgeGroups.find(group => {
          const range = Constants.AgeRanges[group];
          return range && age >= range.min && (age <= range.max || range.max === undefined);
        });
        if (ageGroup) {
          return ageGroup;
        }
      }
    }

    return 'Unknown';
  }

  static vintageName(wine: Wine) {
    return `${wine.vintage ? wine.vintage + ' ' : ''}${wine.name}`;
  }

  static dateFromObjectId(objectId) {
    return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
  }

  static dateStrFromObjectid(objectId) {
    const m = moment(Utils.dateFromObjectId(objectId));
    return m.format('MMMM Do YYYY, h:mm:ss a');
  }

  static beverageImg(beverage: Wine) {
    const image = beverage.image || beverage.label;
    if (!image) {
      return 'assets/wine.svg';
    } else if (image.startsWith('http')) {
      return image;
    } else {
      return `${environment.content_url}/${image}`;
    }
  }

  static containerImg(container: Winery | Merchant) {
    const image = container.image || container.thumb;
    if (!image) {
      return 'assets/winery.svg';
    } else if (image.startsWith('http')) {
      return image;
    } else {
      return `${environment.content_url}/${image}`;
    }
  }

  static beverageVarietal(beverage: Wine) {
    return beverage.category === Constants.BevCategory.SPIRIT
      ? `${beverage.varietal}`
      : `${beverage.vintage || 'Non Vintage'} | ${beverage.name === beverage.varietal ? beverage.appellation : beverage.varietal}`;
  }

  static linkValueValidator(actionName = 'action', labelName = 'label', valueName = 'value'): ValidatorFn {
    return (f: FormGroup): { [key: string]: boolean } | null => {
      const labelCtl = f.controls[labelName];
      const label = labelCtl.value;
      const valueCtl = f.controls[valueName];
      const value: string = valueCtl.value;
      const action = f.controls[actionName].value;

      if (!action) {
        // No action means the form doesn't include a link
        valueCtl.setErrors(null);
        labelCtl.setErrors(null);
        return null;
      }

      if (!label || label.trim().length === 0) {
        labelCtl.setErrors({ linkValue: true });
        labelCtl.markAsTouched();
        return { linkValue: true };
      }
      if (value && value.trim().length > 0) {
        switch (action) {
          case 'browser':
          case 'embedded':
            if (value.match(Constants.URLRegx)) {
              valueCtl.setErrors(null);
              valueCtl.markAsTouched();
              return null;
            }
            break;

          case 'event':
          case 'wine':
          case 'winery':
          case 'wine-list':
          case 'promo':
            if (value.match(Constants.OIDRegx)) {
              valueCtl.setErrors(null);
              valueCtl.markAsTouched();
              return null;
            }
            break;

          case 'html':
            valueCtl.setErrors(null);
            valueCtl.markAsTouched();
            return null;
        }
      }
      valueCtl.setErrors({ linkValue: true });
      valueCtl.markAsTouched();
      return { linkValue: true };
    };
  }

  static volumeString(volume: number) {
    if (!volume) {
      return '';
    }
    if (volume === 9000) {
      return 'Case';
    }
    const litre: boolean = volume >= 1000;
    return `${litre ? volume / 1000 : volume}${litre ? 'L' : 'ml'}`;
  }

  static downloadBlob(blob: Blob, name: string) {
    const a = window.document.createElement('a');
    a.href = window.URL.createObjectURL(blob);
    a.download = name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  static multilinePatternValidator(regEx: RegExp): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const values = control.value.split(/\r?\n/);
      return values.every(v => regEx.test(v)) ? null : { multilinePattern: { value: control.value } };
    };
  }
}
