import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Event } from '../../core/models';
import { List } from 'immutable';
import { NewEventDialogComponent } from '../dialogs';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { ButtonSpec } from '../content-card';
import { ConfirmDialogComponent } from '../dialogs';
import { EventsOp } from '../../scripts/enums';
import * as moment from 'moment';

@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.scss'],
})
export class EventListComponent implements OnInit {
  constructor(private dialog: MatDialog, private router: Router) {}

  @Input() events: List<Event>;
  @Output() action = new EventEmitter<[EventsOp, any]>();
  buttons: ButtonSpec[];

  ngOnInit() {
    this.buttons = [
      { name: 'edit', icon: 'edit', tip: `Edit Event details` },
      { name: 'copy', icon: 'file_copy', tip: `Copy details to new Event` },
      { name: 'delete', icon: 'delete', tip: `Remove Event` },
    ];
  }

  subtitle(event: Event) {
    if (!event.start) {
      return '';
    }
    return event.timeOpt ? `Start: ${moment(event.start).format('LL')}` : `Start: ${moment(event.start).format('LLL')}`;
  }

  eventImg(event: Event) {
    const image = event.thumb || event.image;
    if (!image) {
      return 'assets/winery.svg';
    } else if (image.startsWith('http')) {
      return image;
    } else {
      return `${environment.content_url}/${image}`;
    }
  }

  async eventAction(index, button) {
    switch (button) {
      case 'edit':
        await this.editEvent(index);
        break;

      case 'copy':
        await this.copyEvent(index);
        break;

      case 'delete':
        this.removeEvent(index);
        break;
    }
  }

  async copyEvent(index) {
    const event = this.events.get(index);
    const dialogRef = this.dialog.open(NewEventDialogComponent, {
      width: '500px',
      data: {
        event: event,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const eventDate = moment(result.eventDate);
        if (result.eventTime) {
          const eventTime = moment(result.eventTime, 'LT');
          eventDate.add(eventTime.hours(), 'hours');
          eventDate.add(eventTime.minutes(), 'minutes');
        }
        delete result.eventDate;
        delete result.eventTime;

        result.start = eventDate;
        const eventData = {
          ...event,
          ...result,
          _id: undefined,
        };
        this.action.emit([EventsOp.AddEvent, eventData]);
      }
    });
  }

  async editEvent(index) {
    const event = this.events.get(index);
    this.action.emit([EventsOp.EditEvent, event]);
  }

  addEvent() {
    const dialogRef = this.dialog.open(NewEventDialogComponent, {
      width: '500px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const eventDate = moment(result.eventDate);
        if (result.eventTime) {
          const eventTime = moment(result.eventTime, 'LT');
          eventDate.add(eventTime.hours(), 'hours');
          eventDate.add(eventTime.minutes(), 'minutes');
        } else {
          result.timeOpt = true;
        }
        delete result.eventDate;
        delete result.eventTime;

        result.start = eventDate;
        this.action.emit([EventsOp.AddEvent, result]);
      }
    });
  }

  removeEvent(index) {
    const event = this.events.get(index);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
      data: {
        title: 'Delete Event',
        message:
          `<p>This will remove the event '${event.name}' ` +
          `but will not permenantly remove it from the system.</p>` +
          `<p>Are you sure you want to do this?</p>`,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.action.emit([EventsOp.RemoveEvent, event]);
      }
    });
  }
}
