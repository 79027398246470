import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Story } from '../models';
import { List } from 'immutable';
import { ApiService } from './api.service';
import { finalize } from 'rxjs/operators';

export interface StoryError {
  context: string;
  message: string;
  story?: Story;
  formatted: string;
}

@Injectable({
  providedIn: 'root'
})
export class FeaturesStoreService {

  private _stories: BehaviorSubject<List<Story>> = new BehaviorSubject(List([]));
  private _fetchingStories = false;
  private _fetched = false;

  private _lastError: Subject<StoryError> = new Subject<StoryError>();
  public lastError$: Observable<StoryError> = this._lastError.asObservable();

  constructor(
    private apiService: ApiService,
  ) { }

  get stories$() {
    if (!this._fetchingStories && !this._fetched) {
      this._fetchingStories = true;
      this.apiService.get('/stories?sorted=true').pipe(
        finalize(() => {
          this._fetchingStories = false;
          this._fetched = true;
        })
      ).subscribe(
        stories => {
          this._stories.next(List(stories));
        },
        error => {
          this.setLastError('Retrieving stories', error.message);
        }
      );
    }

    return this._stories.asObservable();
  }

  private setLastError(context: string, message: string, story?: Story) {
    this._lastError.next({
      context: context,
      message: message,
      story: story,
      formatted: `${context} failed because ${message}`
    } as StoryError);
  }

  updateStories(stories: List<Story>): Observable<List<Story>> {
    const storyIds = stories.map(s => s._id);
    this.apiService.put('/stories/app', {stories: storyIds}).subscribe(
      savedStories => {
        this._stories.next(List(savedStories));
      },
    error => {
        this.setLastError('Updating stories', error.message);
      }
    );

    return this.stories$;
  }

  addStory(story: Story): Observable<Story> {
    const returnedStory: BehaviorSubject<Story> = new BehaviorSubject(story);
    this.apiService.post('/stories', story).subscribe(
      newStory => {
        returnedStory.next(newStory);
        this._stories.next(this._stories.getValue().insert(0, newStory));
      },
      error => {
        this.setLastError('Adding story', error.message, story);
      }
    );

    return returnedStory.asObservable();
  }

  saveStory(story: Story): Observable<Story> {
    const returnedStory: BehaviorSubject<Story> = new BehaviorSubject(story);
    const stories = this._stories.getValue();
    const index = stories.findIndex(s => s._id === story._id);
    if (index === -1) {
      this.setLastError('Saving story', 'story not in list', story);
    }
    else {
      this.apiService.put(`/stories/${story._id}`, story).subscribe(
        savedStory => {
          returnedStory.next(savedStory);
          this._stories.next(stories.splice(index, 1, savedStory));
        },
        error => {
          this.setLastError('Saving story', error.message, story);
        }
      );
    }

    return returnedStory.asObservable();
  }

  deleteStory(story: Story): Observable<Story> {
    const returnedStory: BehaviorSubject<Story> = new BehaviorSubject(story);
    const stories = this._stories.getValue();
    const index = stories.findIndex(s => s._id === story._id);
    if (index === -1) {
      throw this.setLastError('Deleting story', 'story not in list', story);
    }
    else {
      this.apiService.delete(`/stories/${story._id}`).subscribe(
        () => {
          returnedStory.next({} as Story);
          this._stories.next(stories.splice(index, 1));
        },
        error => {
          this.setLastError('Deleting story', error.message, story);
        }
      );
    }

    return returnedStory.asObservable();
  }

}
