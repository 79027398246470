import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Event, EventDetail, MerchantDetail } from '../models';
import { List } from 'immutable';
import { ApiService } from './api.service';
import { finalize } from 'rxjs/operators';

export interface EventError {
  context: string;
  message: string;
  event?: Event;
  formatted: string;
}

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  private _events$: BehaviorSubject<List<Event>> = new BehaviorSubject(List([]));
  private _fetchingEvents = false;
  private _fetched = false;

  private _lastError: Subject<EventError> = new Subject<EventError>();
  public lastError$: Observable<EventError> = this._lastError.asObservable();

  constructor(private apiService: ApiService) {}
  get events$() {
    if (!this._fetchingEvents && !this._fetched) {
      this._fetchingEvents = true;
      this.apiService
        .get('/events?past=true')
        .pipe(
          finalize(() => {
            this._fetchingEvents = false;
            this._fetched = true;
          })
        )
        .subscribe(
          events => {
            this._events$.next(List(events));
          },
          error => {
            this.setLastError('Retrieving events', error.message);
          }
        );
    }
    return this._events$.asObservable();
  }

  private setLastError(context: string, message: string, event?: Event) {
    this._lastError.next({
      context: context,
      message: message,
      event: event,
      formatted: `${context} failed because ${message}`,
    } as EventError);
  }

  private updateEventInList(event: Event) {
    const _events = this._events$.getValue();
    const index = _events.findIndex(w => w._id === event._id);
    this._events$.next(_events.set(index, event));
  }

  addEvent(event: Event): Observable<Event> {
    const returnedEvent: BehaviorSubject<Event> = new BehaviorSubject(event);
    this.apiService.post('/events', event).subscribe(
      newEvent => {
        returnedEvent.next(newEvent);
        this._events$.next(this._events$.getValue().insert(0, newEvent));
      },
      error => {
        this.setLastError('Adding Event', error.message, event);
      }
    );

    return returnedEvent.asObservable();
  }

  deleteEvent(event: Event): Observable<Event> {
    const returnedEvent: BehaviorSubject<Event> = new BehaviorSubject(event);
    const events = this._events$.getValue();
    const index = events.findIndex(s => s._id === event._id);
    if (index === -1) {
      throw this.setLastError('Deleting Event', 'event not in list', event);
    } else {
      this.apiService.delete(`/events/${event._id}`).subscribe(
        () => {
          returnedEvent.next({} as Event);
          this._events$.next(events.splice(index, 1));
        },
        error => {
          this.setLastError('Deleting event', error.message, event);
        }
      );
    }

    return returnedEvent.asObservable();
  }

  fetchEvent(eventId: string): Observable<EventDetail> {
    const fetchedEvent$: BehaviorSubject<EventDetail> = new BehaviorSubject<EventDetail>({} as EventDetail);
    const events = this._events$.getValue();
    if (events.find(w => w._id === eventId)) {
      this.apiService.get(`/events/${eventId}`).subscribe(
        eventDetail => {
          fetchedEvent$.next(eventDetail);
        },
        error => {
          this.setLastError('Fetching Event', error.message);
        }
      );
    } else {
      this.setLastError('Fetching Event', `invalid id supplied`);
    }
    return fetchedEvent$.asObservable();
  }

  saveEventDetail(event: EventDetail): Observable<EventDetail> {
    const _events = this._events$.getValue();
    console.log(event);
    const index = _events.findIndex(s => s._id === event._id);
    const event$: BehaviorSubject<EventDetail> = new BehaviorSubject<EventDetail>(_events.get(index) as EventDetail);
    if (index === -1) {
      this.setLastError('Saving Event', 'event not in list', event);
    } else {
      this.apiService.put(`/events/${event._id}`, event).subscribe(
        savedEvent => {
          this.updateEventInList(savedEvent);
          event$.next(savedEvent);
        },
        error => {
          this.setLastError('Saving Event', error.message, event);
        }
      );
    }
    return event$.asObservable();
  }

  addEventProduct(event: EventDetail, product: any) {
    const event$: BehaviorSubject<EventDetail> = new BehaviorSubject<EventDetail>(event);
    this.apiService.post(`/events/${event._id}/products`, product).subscribe(savedEvent => {
      this.updateEventInList(savedEvent);
      event$.next(savedEvent);
    });
    return event$.asObservable();
  }

  removeEventProduct(event: EventDetail, productId: string) {
    const event$: BehaviorSubject<EventDetail> = new BehaviorSubject<EventDetail>(event);
    this.apiService.delete(`/events/${event._id}/products/${productId}`).subscribe(savedEvent => {
      this.updateEventInList(savedEvent);
      event$.next(savedEvent);
    });
    return event$.asObservable();
  }

  updateEventProduct(event: EventDetail, product: any) {
    const event$: BehaviorSubject<EventDetail> = new BehaviorSubject<EventDetail>(event);
    this.apiService.put(`/events/${event._id}/products/${product._id}`, product).subscribe(savedEvent => {
      this.updateEventInList(savedEvent);
      event$.next(savedEvent);
    });
    return event$.asObservable();
  }
}
