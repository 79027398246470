import { Component, OnInit } from '@angular/core';
import { AuthService } from './core/services';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor (
    public auth: AuthService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
  ) {
    iconRegistry.addSvgIcon(
      'qr',
      sanitizer.bypassSecurityTrustResourceUrl('assets/bv_qr.svg'));
    iconRegistry.addSvgIcon(
      'nfc',
      sanitizer.bypassSecurityTrustResourceUrl('assets/bv_nfc.svg'));
  }

  ngOnInit() {
  }
}
