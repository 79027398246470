var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { EventEmitter, OnChanges, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { List } from 'immutable';
import { AuthService } from '../../core';
import { Constants, Utils, MerchantOp } from '../../scripts';
import { NewMerchantDialogComponent } from '../new-merchant-dialog/new-merchant-dialog.component';
export class MerchantListComponent {
    constructor(dialog, auth) {
        this.dialog = dialog;
        this.auth = auth;
        this.action = new EventEmitter();
        this.merchantFilter = Constants.WineFilters[0];
        this.filters = Constants.WineFilters;
        this.filterMerchants = (producer) => {
            if (!this.filterString || this.filterString.trim().length === 0) {
                return true;
            }
            return producer.name.toLowerCase().includes(this.filterString.toLowerCase());
        };
    }
    ngOnInit() {
        this.buttons = [{ name: 'edit', icon: 'edit', tip: `Edit ${this.category} details` }];
    }
    ngOnChanges() {
        this.merchantFilter = Constants.WineFilters.find(f => f.value === this.filter);
        if (this.rawMerchants) {
            this.counts = { active: 0, inactive: 0, all: 0 };
            this.rawMerchants.forEach(p => {
                this.counts.all++;
                if (p.inactive) {
                    this.counts.inactive++;
                }
                else {
                    this.counts.active++;
                }
            });
        }
    }
    applyFilter() {
        this.action.emit([MerchantOp.FilterMerchants, this.filter]);
    }
    merchantAction(merchant, button) {
        return __awaiter(this, void 0, void 0, function* () {
            switch (button) {
                case 'edit':
                    yield this.editMerchant(merchant);
                    break;
            }
        });
    }
    editMerchant(merchant) {
        this.action.emit([MerchantOp.EditMerchant, merchant]);
    }
    addMerchant() {
        const dialogRef = this.dialog.open(NewMerchantDialogComponent, {
            width: '500px',
            data: {
                category: this.category,
            },
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                result.category = this.category;
            }
            this.action.emit([MerchantOp.AddMerchant, result]);
        });
    }
    subtitle(merchant) {
        if (!merchant || !merchant.contact || !merchant.contact.address) {
            return '';
        }
        const addr = merchant.contact.address;
        return addr.locality ? `${addr.locality}, ${addr.region || ''}` : `${addr.region || ''} ${addr.country || ''}`;
    }
    merchantImage(merchant) {
        return Utils.containerImg(merchant);
    }
}
