<div class="page">
  <div *ngIf="!auth.loggedIn" class="banner">
    <div class="container">
      <h2 class="logo-font">BottleVin</h2>
      <p>The future of selling, marketing and identifying your wine.</p>
    </div>
  </div>

  <div *ngIf="auth.loggedIn" fxLayout="row" fxLayoutGap="20px">
    <div fxFlex="100%">
      <mat-tab-group [(selectedIndex)]="tabIndex" (animationDone)="animationDone()">
        <mat-tab label="Wineries">
          <app-producer-list
            category="winery"
            [producers]="wineries$ | async"
            [rawProducers]="wineriesRaw$ | async"
            [filter]="wineryStore.wineryFilter"
            (action)="producerAction($event)"
          ></app-producer-list>
        </mat-tab>
        <mat-tab label="Distilleries">
          <app-producer-list
            category="distillery"
            [producers]="distilleries$ | async"
            [rawProducers]="distilleriesRaw$ | async"
            [filter]="wineryStore.wineryFilter"
            (action)="producerAction($event)"
          ></app-producer-list>
        </mat-tab>
        <mat-tab label="Restaurants">
          <app-merchant-list
            category="restaurant"
            [merchants]="restaurants$ | async"
            [rawMerchants]="restaurantsRaw$ | async"
            [filter]="merchantStore.merchantFilter"
            (action)="merchantAction($event)"
          ></app-merchant-list>
        </mat-tab>
        <mat-tab label="Shops">
          <app-merchant-list
            category="shop"
            [merchants]="shops$ | async"
            [rawMerchants]="shopsRaw$ | async"
            [filter]="merchantStore.merchantFilter"
            (action)="merchantAction($event)"
          ></app-merchant-list>
        </mat-tab>
        <mat-tab label="Distributors">
          <app-merchant-list
            category="distributor"
            [merchants]="distributors$ | async"
            [rawMerchants]="distributorsRaw$ | async"
            [filter]="merchantStore.merchantFilter"
            (action)="merchantAction($event)"
          ></app-merchant-list>
        </mat-tab>
        <mat-tab *ngIf="auth.isAdmin() || auth.appAdmin()" label="Events">
          <app-event-list [events]="eventService.events$ | async" (action)="eventsAction($event)"></app-event-list>
        </mat-tab>
        <mat-tab *ngIf="auth.isAdmin() || auth.appAdmin()" label="Promotions">
          <app-promo-list
            [promos]="(promoService.promos$ | async).toArray()"
            [settings]="wineryStore.settings$ | async"
            (action)="promoAction($event)"
          ></app-promo-list>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
