import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Utils, EventsOp } from '../../scripts';
import { Event } from '../../core';
import * as moment from 'moment';

function eventRequiredDateTime(f: FormGroup): { [key: string]: boolean } | null {
  const eventDate = f.controls['eventDate'].value;
  const eventTime = f.controls['eventTime'].value;
  const now = new Date();
  if (!eventDate) {
    return {
      eventRequiredDateTime: false,
    };
  }
  const eventTimeParse = eventTime.split(':');
  const eventTimeDate = new Date(2019, 1, 1, eventTimeParse[0], eventTimeParse[1]);
  if (moment(eventDate).isSame(now, 'day')) {
    if (eventTimeDate && eventTimeDate.getHours() < now.getHours()) {
      return {
        eventRequiredDateTime: false,
      };
    } else if (eventTimeDate && eventTimeDate.getHours() === now.getHours()) {
      if (eventTimeDate.getMinutes() <= now.getMinutes()) {
        return {
          eventRequiredDateTime: false,
        };
      }
    }
  }
  return null;
}

export interface NewEventDialogData {
  event: Event;
}

@Component({
  selector: 'app-new-event-dialog',
  templateUrl: './new-event-dialog.component.html',
  styleUrls: [],
})
export class NewEventDialogComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: NewEventDialogData, private fb: FormBuilder) {}
  newEventForm = this.fb.group(
    {
      name: ['', Utils.notEmpty],
      eventDate: [''],
      eventTime: [''],
    },
    { validators: [eventRequiredDateTime] }
  );

  @Output() action = new EventEmitter<[EventsOp, any]>();
  public todaysDate = new Date();

  ngOnInit() {
    if (this.data) {
      if (this.data.event) {
        this.newEventForm.patchValue({
          ...this.data.event,
        });
      }
    }
  }
}
