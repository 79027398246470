import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { EditorModule } from '@tinymce/tinymce-angular';
import { QRCodeModule } from 'angularx-qrcode';
import { MomentModule } from 'ngx-moment';

import { ConfirmDialogComponent, ErrorDialogComponent, NewEventDialogComponent, PromoDialogComponent } from './dialogs';
import { MaterialModule } from './material.module';
import { ImageUploadComponent } from './image-upload';
import { UpcCardComponent } from './upc-card';
import { InfoButtonComponent } from './info-button';
import { TagUploadComponent } from './tag-upload/tag-upload.component';
import { HtmlEditorComponent } from './html-editor/html-editor.component';
import { QrTagComponent } from './qr-tag/qr-tag.component';
import { QrDialogComponent } from './qr-tag/qr-dialog.component';
import { EventListComponent } from './event-list/event-list.component';
import { ContentCardComponent } from './content-card';
import { LinksFormComponent } from './links-form';
import { ProductCardComponent } from './product-card';
import { LiveEventComponent } from './live-event/live-event.component';
import { PromoListComponent } from './promo-list';
import { FilterListPipe } from './filterList.pipe';

// noinspection AngularInvalidImportedOrDeclaredSymbol
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    MaterialModule,
    EditorModule,
    QRCodeModule,
    MomentModule,
  ],
  declarations: [
    ConfirmDialogComponent,
    ErrorDialogComponent,
    NewEventDialogComponent,
    ImageUploadComponent,
    UpcCardComponent,
    InfoButtonComponent,
    TagUploadComponent,
    HtmlEditorComponent,
    QrTagComponent,
    QrDialogComponent,
    EventListComponent,
    ContentCardComponent,
    LinksFormComponent,
    ProductCardComponent,
    LiveEventComponent,
    PromoListComponent,
    PromoDialogComponent,
    FilterListPipe,
  ],
  entryComponents: [ConfirmDialogComponent, ErrorDialogComponent, QrDialogComponent, NewEventDialogComponent, PromoDialogComponent],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ConfirmDialogComponent,
    ImageUploadComponent,
    UpcCardComponent,
    RouterModule,
    InfoButtonComponent,
    TagUploadComponent,
    EditorModule,
    HtmlEditorComponent,
    QrTagComponent,
    EventListComponent,
    ContentCardComponent,
    LinksFormComponent,
    ProductCardComponent,
    LiveEventComponent,
    PromoListComponent,
    FilterListPipe,
  ],
})
export class SharedModule {}
