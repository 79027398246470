import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const HTML_WRAPPER = '<!DOCTYPE html><html lang="en-US"><head>' +
  `<base href="${window.location.origin}">` +
  '<link type="text/css" rel="stylesheet" href="/assets/embedded.css">' +
  '<meta name="viewport" content="width=device-width, initial-scale=1.0">' +
  '<title>$title</title></head><body>$body</body></html>';

@Component({
  selector: 'app-html-editor',
  templateUrl: './html-editor.component.html',
  styleUrls: ['./html-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HtmlEditorComponent),
      multi: true
    }
  ]
})

export class HtmlEditorComponent implements OnInit, ControlValueAccessor {

  constructor() { }

  @Input() active: boolean;
  @Input() id: string;
  @Input()
  set label(label: string) {
    // Set the label value to display and also set it as the title in the HTML code
    this._label = label;
    this._html = HTML_WRAPPER.replace('$title', label);
  }

  get label() {
    return this._label;
  }

  get value() {
    return this._value;
  }

  set value(value: string) {
    // Set the value being edited and wrap it in the HTML code for the outside world
    if (this._value !== value) {
      if (!value) {
        this.onChange(value);
      }
      else {
        this.onChange(this._html.replace('$body', value));
      }
      this.onTouched();
      this._value = value;
    }
  }

  public editorInit = {
    min_height: 400,
    max_height: 667,
    plugins: ['advlist', 'autolink', 'charmap', 'code', 'help', 'hr', 'image', 'link', 'lists', 'media', 'paste', 'preview'],
    toolbar: 'undo redo | styleselect | bold italic underline | bullist numlist | alignleft aligncenter alignright alignjustify | ' +
      'forecolor backcolor | outdent indent | link image',
    toolbar_drawer: 'floating',
    menubar: 'edit insert view format help',
    menu: {
      edit: {title: 'Edit', items: 'undo redo | cut copy paste pastetext | selectall '},
      insert: {title: 'Insert', items: 'charmap hr link image media'},
      view: {title: 'View', items: 'visualaid preview code'},
      format: {
        title: 'Format',
        items: 'bold italic underline strikethrough superscript subscript | fontformats fontsizes | removeformat'
      },
      help: {title: 'Help', items: 'help'}
    },
    font_formats: 'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; BottleVin Default=Oswald,sans-serif;' +
      'Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Terminal=terminal,monaco; ' +
      'Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva;',
    image_caption: true,
    content_css: '/assets/embedded.css'
  };

  private _value: string;
  private _label: string;
  private _html: string;

  onChange: any = () => {};
  onTouched: any = () => {};

  ngOnInit() {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(content: any): void {
    // Parse out just the body to use in the editor
    if (content) {
      const start = content.indexOf('<body>');
      if (start !== -1) {
        let end = content.indexOf('</body>');
        if (end === -1 || end < start) {
          end = undefined;
        }
        this._value = content.slice(start + 6, end);
      }
      else {
        this._value = content;
      }
    }
    else {
      this._value = content;
    }
  }

}
