import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { List } from 'immutable';
import { Constants } from '../../scripts';

import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import {
  Promo,
  Settings,
  Tag,
  Varietals,
  Wine,
  WineDetail,
  Winery,
  WineryDetail,
  WineTasting,
  Product,
  ExpandedProduct,
  DocList,
} from '../models';
import { HttpParams } from '@angular/common/http';
import { finalize, map } from 'rxjs/operators';

export interface WineStoreError {
  message: string;
  detail?: Wine | WineTasting;
  winery: Winery;
  context: string;
  formatted: string;
}

@Injectable({
  providedIn: 'root',
})
export class WineryStoreService {
  constructor(private apiService: ApiService, private auth: AuthService) {}

  get settings$(): Observable<Settings> {
    if (this.auth.loggedIn && !this._fetchingSettings && !this._settingsFetched) {
      this._fetchingSettings = true;
      this.apiService
        .get('/settings')
        .pipe(
          finalize(() => {
            this._fetchingSettings = false;
          })
        )
        .subscribe(
          settings => {
            this._settingsFetched = true;
            this._settings.next(settings);
          },
          error => {
            this.setLastError('Fetching settings', error.message);
            this._settingsFetched = true;
            this._settings.next({});
          }
        );
    }
    return this._settings$;
  }

  get styles$(): Observable<string[]> {
    if (!this._fetchingStyles && this._styles.getValue().length === 0) {
      this._fetchingStyles = true;
      this.apiService
        .get('/styles')
        .pipe(
          finalize(() => {
            this._fetchingStyles = false;
          })
        )
        .subscribe(
          styles => {
            this._styles.next(styles);
          },
          error => {
            this.setLastError('Fetching styles', error.message);
            this._styles.next([]);
          }
        );
    }

    return this._styles$;
  }

  get varietals$(): Observable<Varietals> {
    const curVal: Varietals = this._varietals.getValue();
    if (!this._fetchingVarietals && curVal.red.length === 0 && curVal.white.length === 0) {
      this._fetchingVarietals = true;
      this.apiService
        .get('/varietals')
        .pipe(
          finalize(() => {
            this._fetchingVarietals = false;
          })
        )
        .subscribe(
          varietals => {
            this._varietals.next(varietals);
          },
          error => {
            this.setLastError('Fetching varietals', error.message);
            this._varietals.next({ red: [], white: [] });
          }
        );
    }

    return this._varietals$;
  }

  private _wineryFilter = JSON.parse(localStorage.getItem('wineryFilter')) || 'all';
  public get wineryFilter() {
    return this._wineryFilter;
  }

  public set wineryFilter(filterValue: string) {
    this._wineryFilter = filterValue;
    this._wineries$.next(this._wineries$.value);
    localStorage.setItem('wineryFilter', JSON.stringify(filterValue));
  }

  private _wineFilter$: BehaviorSubject<string> = new BehaviorSubject<string>('active');
  public get wineFilter$(): Observable<string> {
    return this._wineFilter$.asObservable();
  }

  public set wineFilter(filterValue: string) {
    this._wineFilter$.next(filterValue);
  }

  private _fetchingWineries = false;
  private _wineriesFetched = false;
  private _fetchingAll = false;
  private _allFetched = false;
  private _wineries$: BehaviorSubject<List<Winery>> = new BehaviorSubject(List([]));

  public get wineriesRaw$() {
    this.auth.loggedIn$.subscribe(loggedIn => {
      if (!this._fetchingWineries && !this._wineriesFetched) {
        if (loggedIn) {
          this._fetchingWineries = true;
          this.apiService
            .get('/wineries')
            .pipe(
              finalize(() => {
                this._fetchingWineries = false;
                this._wineriesFetched = true;
              })
            )
            .subscribe(wineries => {
              this._wineries$.next(List(wineries));
            });
        }
      }
    });

    return this._wineries$.asObservable();
  }

  get wineriesAll$() {
    if (!this._fetchingAll && !this._allFetched) {
      let params: HttpParams;
      if (this.auth.isAdmin()) {
        params = new HttpParams().set('details', 'true');
      }
      this._fetchingAll = true;
      this.apiService
        .get('/wineries', params)
        .pipe(
          finalize(() => {
            this._fetchingAll = false;
            this._allFetched = true;
          })
        )
        .subscribe(wineries => {
          if (this.auth.isAdmin()) {
            wineries = [this._allWinery, ...wineries];
          }
          this._wineries$.next(List(wineries));
        });
    }

    return this._wineries$.asObservable();
  }

  // This list may be filtered
  public wineries$: Observable<List<Winery>> = this.wineriesRaw$.pipe(map(w => this.filterWineries(w)));

  private _fetchingSettings = false;
  private _settingsFetched = false;
  private _settings: BehaviorSubject<Settings> = new BehaviorSubject<object>({});
  private _settings$: Observable<Settings> = this._settings.asObservable();

  private _fetchingStyles = false;
  private _styles: BehaviorSubject<string[]> = new BehaviorSubject([]);
  private _styles$: Observable<string[]> = this._styles.asObservable();

  private _fetchingVarietals = false;
  private _varietals: BehaviorSubject<Varietals> = new BehaviorSubject({ red: [], white: [] } as Varietals);
  private _varietals$: Observable<Varietals> = this._varietals.asObservable();

  private _lastError: Subject<WineStoreError> = new Subject<WineStoreError>();
  public lastError$: Observable<WineStoreError> = this._lastError.asObservable();

  private readonly _AllWineryID = '__ALL__';

  // This is placeholder entry for selected all wineries within the list (only available to Admin)
  private _allWinery: Winery = {
    _id: this._AllWineryID,
    name: 'All Producers',
    contact: {
      address: {
        street_address: '2905 Stender Way',
        locality: 'Santa Clara',
        region: 'CA',
        postal_code: '95054',
        country: 'USA',
      },
      email: 'admin@bottlevin.com',
      phone: '',
      website: 'https://www.bottlevin.com',
    },
  };

  static applyFilter(w, filter) {
    switch (filter) {
      case 'active':
        return !w.inactive;
      case 'inactive':
        return w.inactive;
      default:
        return true;
    }
  }

  private filterWineries(wineries: List<Winery>): List<Winery> {
    return wineries.filter(w => {
      if (this.isAll(w)) {
        return true;
      }
      return WineryStoreService.applyFilter(w, this._wineryFilter);
    });
  }

  isAll(winery): boolean {
    return winery._id === this._AllWineryID;
  }

  private setLastError(context: string, message: string, winery?: Winery, detail?: Wine | WineTasting) {
    const lastError = {
      context: context,
      message: message,
      winery: winery,
      detail: detail,
      formatted: `${context} failed because ${message}`,
    } as WineStoreError;
    this._lastError.next(lastError);
  }

  private updateWineryInList(winery: Winery) {
    const _wineries = this._wineries$.getValue();
    const index = _wineries.findIndex(w => w._id === winery._id);
    this._wineries$.next(_wineries.set(index, winery));
  }

  getCountries(): Observable<string[]> {
    const regionList: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
    this.apiService.get('/countries').subscribe(countries => {
      regionList.next(countries);
    });
    return regionList.asObservable();
  }

  getRegions(country: string): Observable<string[]> {
    const regionList: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
    this.apiService.get(`/regions/${country}`).subscribe(regions => {
      regionList.next(regions);
    });
    return regionList.asObservable();
  }

  getAppellations(country: string, region: string): Observable<string[]> {
    const avaList: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
    this.apiService.get(`/regions/${country}/${region}`).subscribe(appellations => {
      avaList.next(appellations);
    });

    return avaList.asObservable();
  }

  /**
   * Retrieve a winery by ID without making it the selected winery
   *
   * @param wineryId - the winery to retreive
   * @param force - retrieve a new copy even if we have a full winery detail in the list
   */

  fetchWinery(wineryId: string, force: boolean = false): Observable<WineryDetail> {
    const _wineries = this._wineries$.getValue();
    const _winery = _wineries.find(w => w._id === wineryId);
    const winery$: BehaviorSubject<WineryDetail> = new BehaviorSubject<WineryDetail>({} as WineryDetail);
    if (_winery) {
      const wineryDetail = _winery as WineryDetail;
      if (!force && Array.isArray(wineryDetail.admins)) {
        // If we already have a full winery detail just use the current one in the list
        console.log(`Returning cached winery ${wineryId}`);
        winery$.next(wineryDetail);
      } else {
        console.log(`Fetching winery ${wineryId}`);
        this.apiService.get(`/wineries/${wineryId}`).subscribe(
          winery => {
            this.updateWineryInList(winery);
            winery$.next(winery);
          },
          error => {
            this.setLastError('Fetching producer', error.message);
          }
        );
      }
    } else {
      this.setLastError(`Fetching producer`, `producer with id ${wineryId} not in list`);
    }

    return winery$.asObservable();
  }

  filteredWinery(winery: Winery, wineFilter: string): Winery {
    const collection = Constants.Prod2Collection[winery.category];
    const filtered = winery[collection] && winery[collection].filter(w => WineryStoreService.applyFilter(w, wineFilter));
    return {
      ...winery,
      [collection]: filtered,
    };
  }

  filteredWineryDetail(winery: WineryDetail, wineFilter: string): WineryDetail {
    const collection = Constants.Prod2Collection[winery.category];
    const filtered = winery[collection] && winery[collection].filter(w => WineryStoreService.applyFilter(w, wineFilter));
    return {
      ...winery,
      [collection]: filtered,
    };
  }

  addWinery(winery: Winery): Observable<WineryDetail> {
    const winery$ = new BehaviorSubject<WineryDetail>({} as WineryDetail);
    this.apiService.post('/wineries', winery).subscribe(
      newWinery => {
        this._wineries$.next(this._wineries$.getValue().push(newWinery));
        winery$.next(newWinery);
      },
      error => {
        this.setLastError(`Adding ${winery.category}`, error.message, winery);
      }
    );

    return winery$.asObservable();
  }

  saveWinery(winery: WineryDetail): Observable<WineryDetail> {
    const _wineries = this._wineries$.getValue();
    const index = _wineries.findIndex(w => w._id === winery._id);
    const winery$: BehaviorSubject<WineryDetail> = new BehaviorSubject<WineryDetail>(_wineries.get(index) as WineryDetail);
    if (!this.isAll(winery)) {
      this.apiService.put(`/wineries/${winery._id}`, winery).subscribe(
        savedWinery => {
          this.updateWineryInList(savedWinery);
          winery$.next(savedWinery);
        },
        error => {
          this.setLastError(`Saving ${winery.category}`, error.message, winery);
        }
      );
    }
    return winery$.asObservable();
  }

  addPromo(winery: WineryDetail, promo: Promo): Observable<WineryDetail> {
    const winery$: BehaviorSubject<WineryDetail> = new BehaviorSubject<WineryDetail>(winery);
    this.apiService.post(`/wineries/${winery._id}/promos`, promo).subscribe(
      updatedWinery => {
        this.updateWineryInList(updatedWinery);
        winery$.next(updatedWinery);
      },
      error => {
        this.setLastError(`Adding promo to ${winery.category}`, error.message, winery);
      }
    );
    return winery$.asObservable();
  }

  updatePromo(winery: WineryDetail, promo: Promo): Observable<WineryDetail> {
    const winery$: BehaviorSubject<WineryDetail> = new BehaviorSubject<WineryDetail>(winery);
    this.apiService.put(`/wineries/${winery._id}/promos/${promo._id}`, promo).subscribe(
      updatedWinery => {
        this.updateWineryInList(updatedWinery);
        winery$.next(updatedWinery);
      },
      error => {
        this.setLastError(`Updating promo in ${winery.category}`, error.message, winery);
      }
    );
    return winery$.asObservable();
  }

  removePromo(winery: WineryDetail, promo: Promo): Observable<WineryDetail> {
    const winery$: BehaviorSubject<WineryDetail> = new BehaviorSubject<WineryDetail>(winery);
    this.apiService.delete(`/wineries/${winery._id}/promos/${promo._id}`).subscribe(
      updatedWinery => {
        this.updateWineryInList(updatedWinery);
        winery$.next(updatedWinery);
      },
      error => {
        this.setLastError(`Removing promo from ${winery.category}`, error.message, winery);
      }
    );
    return winery$.asObservable();
  }

  fetchProducts(winery: Winery): Observable<ExpandedProduct[]> {
    const wineProducts: BehaviorSubject<ExpandedProduct[]> = new BehaviorSubject([]);

    this.apiService.get(`/wineries/${winery._id}/products`).subscribe(
      products => {
        wineProducts.next(products);
      },
      error => {
        this.setLastError('Fetching products', error.message, winery);
      }
    );

    return wineProducts.asObservable();
  }

  addWine(winery: WineryDetail, wine: Wine): Observable<WineryDetail> {
    const collection = Constants.Bev2Collection[wine.category];
    const winery$: BehaviorSubject<WineryDetail> = new BehaviorSubject<WineryDetail>(winery);
    this.apiService.post(`/wineries/${winery._id}/${collection}`, wine).subscribe(
      updatedWinery => {
        this.updateWineryInList(updatedWinery);
        winery$.next(updatedWinery);
      },
      error => {
        this.setLastError('Adding wine', error.message, winery, wine);
      }
    );
    return winery$.asObservable();
  }

  copyWine(winery: WineryDetail, wineId: string, newWine: Wine): Observable<WineryDetail> {
    const collection = Constants.Bev2Collection[newWine.category];
    const winery$: BehaviorSubject<WineryDetail> = new BehaviorSubject<WineryDetail>(winery);
    // Make sure there is no ID on the new wine data to copy
    delete newWine._id;
    const items = collection === 'oils' ? winery.sundries[collection] : winery[collection];
    if (items.find(w => w._id === wineId)) {
      this.apiService.post(`/wineries/${winery._id}/${collection}/${wineId}`, newWine).subscribe(
        updatedWinery => {
          this.updateWineryInList(updatedWinery);
          winery$.next(updatedWinery);
        },
        error => {
          this.setLastError('Copying wine', error.message, winery, newWine);
        }
      );
    } else {
      this.setLastError('Copying wine', `invalid ${collection} id ${wineId} supplied`, winery, newWine);
    }

    return winery$.asObservable();
  }

  removeWine(winery: WineryDetail, wine: Wine): Observable<WineryDetail> {
    const collection = Constants.Bev2Collection[wine.category];
    const wineId = wine._id;
    const winery$: BehaviorSubject<WineryDetail> = new BehaviorSubject<WineryDetail>(winery);
    const items = collection === 'oils' ? winery.sundries[collection] : winery[collection];
    if (items.find(w => w._id === wineId)) {
      this.apiService.delete(`/wineries/${winery._id}/${collection}/${wineId}`).subscribe(updatedWinery => {
        this.updateWineryInList(updatedWinery);
        winery$.next(updatedWinery);
      });
    } else {
      this.setLastError('Removing wine', `invalid ${collection} id ${wineId} supplied`, winery);
    }
    return winery$.asObservable();
  }

  fetchWine(winery: Winery, wineId: string, collection: string = ''): Observable<WineDetail> {
    collection = collection || Constants.Prod2Collection[winery.category];
    const items = collection === 'oils' ? winery.sundries[collection] : winery[collection];
    const windex = items.findIndex(w => w._id === wineId);
    const wine$: BehaviorSubject<WineDetail> = new BehaviorSubject<WineDetail>({} as WineDetail);
    if (windex !== -1) {
      const wineDetail = items[windex] as WineDetail;
      if (Array.isArray(wineDetail.images)) {
        // If this already looks like a fully fetched wine, don't retrieve it again
        console.log(`Returning cached wine ${wineId}`);
        wine$.next(wineDetail);
      } else {
        console.log(`Fetching wine ${wineId}`);
        this.apiService.get(`/wineries/${winery._id}/${collection}/${wineId}`).subscribe(
          fetchedWine => {
            items[windex] = fetchedWine;
            this.updateWineryInList(winery);
            wine$.next(fetchedWine);
          },
          error => {
            this.setLastError(`Fetching beverage`, error.message, winery);
          }
        );
      }
    } else {
      this.setLastError('Fetching beverage', `invalid ${collection} id ${wineId} supplied`, winery);
    }
    return wine$.asObservable();
  }

  saveWine(winery: Winery, wine: WineDetail): Observable<WineDetail> {
    const collection = Constants.Bev2Collection[wine.category];
    const items = collection === 'oils' ? winery.sundries[collection] : winery[collection];
    const windex = items.findIndex(w => w._id === wine._id);
    const wine$: BehaviorSubject<WineDetail> = new BehaviorSubject<WineDetail>(wine);
    if (windex !== -1) {
      this.apiService.put(`/wineries/${winery._id}/wines/${wine._id}`, wine).subscribe(
        savedWine => {
          items[windex] = savedWine;
          this.updateWineryInList(winery);
          wine$.next(savedWine);
        },
        error => {
          this.setLastError('Saving wine', error.message, winery, wine);
        }
      );
    } else {
      this.setLastError('Saving wine', `${wine.category} ${wine.name} not found in ${winery.category}`, winery);
    }
    return wine$.asObservable();
  }

  fetchProductsForWine(winery: Winery, wineId: string): Observable<List<Product>> {
    const wineProducts: BehaviorSubject<List<Product>> = new BehaviorSubject(List([]));

    this.apiService.get(`/wineries/${winery._id}/wines/${wineId}/products`).subscribe(
      products => {
        wineProducts.next(products);
      },
      error => {
        this.setLastError('Fetching products', error.message, winery);
      }
    );

    return wineProducts.asObservable();
  }

  addProductForWine(winery: Winery, wineId: string, product: Product, products: List<Product>): Observable<List<Product>> {
    const wineProducts: BehaviorSubject<List<Product>> = new BehaviorSubject(products);

    this.apiService.post(`/wineries/${winery._id}/wines/${wineId}/products`, product).subscribe(
      newProducts => {
        wineProducts.next(newProducts);
      },
      error => {
        this.setLastError('Adding product', error.message, winery);
      }
    );

    return wineProducts.asObservable();
  }

  updateProductForWine(winery: Winery, wineId: string, product: Product, products: List<Product>): Observable<List<Product>> {
    const wineProducts: BehaviorSubject<List<Product>> = new BehaviorSubject(products);

    this.apiService.put(`/wineries/${winery._id}/wines/${wineId}/products/${product._id}`, product).subscribe(
      newProducts => {
        wineProducts.next(newProducts);
      },
      error => {
        this.setLastError('Saving product', error.message, winery);
      }
    );

    return wineProducts.asObservable();
  }

  removeProductForWine(winery: Winery, wineId: string, product: Product, products: List<Product>): Observable<List<Product>> {
    const wineProducts: BehaviorSubject<List<Product>> = new BehaviorSubject(products);

    this.apiService.delete(`/wineries/${winery._id}/wines/${wineId}/products/${product._id}`).subscribe(
      newProducts => {
        wineProducts.next(newProducts);
      },
      error => {
        this.setLastError('Removing product', error.message, winery);
      }
    );

    return wineProducts.asObservable();
  }

  fetchTastingByIndex(winery: Winery, index: number, force: boolean = false): Observable<WineTasting> {
    const tasting$: BehaviorSubject<WineTasting> = new BehaviorSubject({} as WineTasting);
    if (winery.menu.length > index) {
      const tasting = winery.menu[index];
      if (!force && Array.isArray(tasting.products)) {
        tasting$.next(tasting);
      } else {
        this.apiService.get(`/wineries/${winery._id}/tastings/${tasting._id}`).subscribe(
          wineTasting => {
            winery.menu[index] = wineTasting;
            this.updateWineryInList(winery);
            tasting$.next(wineTasting);
          },
          error => {
            this.setLastError('Fetching tasting', error.message, winery, tasting);
          }
        );
      }
    } else {
      this.setLastError('Fetching tasting', `invalid index ${index} supplied`, winery);
    }
    return tasting$.asObservable();
  }

  saveTasting(winery: Winery, tasting: WineTasting): Observable<WineTasting> {
    const tasting$: BehaviorSubject<WineTasting> = new BehaviorSubject({} as WineTasting);
    this.apiService.put(`/wineries/${winery._id}/tastings/${tasting._id}`, tasting).subscribe(
      tastingDetail => {
        const tindex = winery.menu.findIndex(t => t._id === tasting._id);
        winery.menu[tindex] = tastingDetail;
        this.updateWineryInList(winery);
        tasting$.next(tastingDetail);
      },
      error => {
        this.setLastError('Saving wine', error.message, winery, tasting);
      }
    );
    return tasting$.asObservable();
  }

  addTasting(winery: WineryDetail, tasting: WineTasting): Observable<WineryDetail> {
    const winery$: BehaviorSubject<WineryDetail> = new BehaviorSubject<WineryDetail>(winery);
    this.apiService.post(`/wineries/${winery._id}/tastings`, tasting).subscribe(
      updatedWinery => {
        this.updateWineryInList(updatedWinery);
        winery$.next(updatedWinery);
      },
      error => {
        this.setLastError('Adding tasting', error.message, winery, tasting);
      }
    );

    return winery$.asObservable();
  }

  removeTastingByIndex(winery: WineryDetail, index: number): Observable<WineryDetail> {
    const tasting = winery.menu[index];
    const winery$: BehaviorSubject<WineryDetail> = new BehaviorSubject<WineryDetail>(winery);
    this.apiService.delete(`/wineries/${winery._id}/tastings/${tasting._id}`).subscribe(
      updatedWinery => {
        this.updateWineryInList(updatedWinery);
        winery$.next(updatedWinery);
      },
      error => {
        this.setLastError('Removing tasting', error.message, winery, tasting);
      }
    );

    return winery$.asObservable();
  }

  getUpcs(winery: WineryDetail): Observable<List<Tag>> {
    const upcs$: BehaviorSubject<List<Tag>> = new BehaviorSubject(List([]));

    if (winery.upcs.length === 0 || typeof winery.upcs[0] === 'object') {
      console.log(`Returning cached upcs for ${winery._id}`);
      const tags = winery.upcs as Tag[];
      upcs$.next(List(tags));
    } else {
      console.log(`Fetching upcs for ${winery._id}`);
      this.apiService.get(`/wineries/${winery._id}/upcs`).subscribe(
        tags => {
          winery.upcs = tags;
          this.updateWineryInList(winery);
          upcs$.next(tags);
        },
        error => {
          this.setLastError('Fetching UPCs', error.message, winery);
        }
      );
    }

    return upcs$.asObservable();
  }

  addUpc(winery: WineryDetail, data): Observable<WineryDetail> {
    const winery$: BehaviorSubject<WineryDetail> = new BehaviorSubject<WineryDetail>(winery);
    this.apiService.post(`/wineries/${winery._id}/upcs`, data).subscribe(
      updatedWinery => {
        this.updateWineryInList(updatedWinery);
        winery$.next(updatedWinery);
      },
      error => {
        this.setLastError('Adding UPC', error.message, winery);
      }
    );
    return winery$.asObservable();
  }

  editUpcs(winery: WineryDetail, data): Observable<WineryDetail> {
    const winery$: BehaviorSubject<WineryDetail> = new BehaviorSubject<WineryDetail>(winery);
    this.apiService.put(`/wineries/${winery._id}/upcs/${data.tagId}`, data).subscribe(
      updatedWinery => {
        this.updateWineryInList(updatedWinery);
        winery$.next(updatedWinery);
      },
      error => {
        this.setLastError('Editing UPC', error.message, winery);
      }
    );
    return winery$.asObservable();
  }

  deleteUpc(winery: WineryDetail, data): Observable<WineryDetail> {
    const winery$: BehaviorSubject<WineryDetail> = new BehaviorSubject<WineryDetail>(winery);
    this.apiService.delete(`/wineries/${winery._id}/upcs/${data.tagId}`).subscribe(
      updatedWinery => {
        this.updateWineryInList(updatedWinery);
        winery$.next(updatedWinery);
      },
      error => {
        this.setLastError('Removing UPC', error.message, winery);
      }
    );
    return winery$.asObservable();
  }

  getDocs(winery: WineryDetail): Observable<List<DocList>> {
    const docs$: BehaviorSubject<List<DocList>> = new BehaviorSubject(List([]));

    if (winery.docs.length === 0 || typeof winery.docs[0] === 'object') {
      console.log(`Returning cached docs for ${winery._id}`);
      const docs = winery.docs as DocList[];
      docs$.next(List(docs));
    } else {
      console.log(`Fetching docs for ${winery._id}`);
      this.apiService.get(`/wineries/${winery._id}/docs`).subscribe(
        docs => {
          winery.docs = docs;
          this.updateWineryInList(winery);
          docs$.next(List(docs));
        },
        error => {
          this.setLastError('Fetching Docs', error.message, winery);
        }
      );
    }
    return docs$.asObservable();
  }

  addDocList(winery: WineryDetail, data: DocList): Observable<List<DocList>> {
    const docs$: BehaviorSubject<List<DocList>> = new BehaviorSubject<List<DocList>>(List(winery.docs));
    this.apiService.post(`/wineries/${winery._id}/docs`, data).subscribe(
      updatedWinery => {
        this.updateWineryInList(updatedWinery);
        docs$.next(List(updatedWinery.docs));
      },
      error => {
        this.setLastError('Adding Doc List', error.message, winery);
      }
    );
    return docs$.asObservable();
  }

  updateDocList(winery: WineryDetail, data: DocList): Observable<List<DocList>> {
    const docs$: BehaviorSubject<List<DocList>> = new BehaviorSubject<List<DocList>>(List(winery.docs));
    this.apiService.put(`/wineries/${winery._id}/docs/${data._id}`, data).subscribe(
      updatedWinery => {
        this.updateWineryInList(updatedWinery);
        docs$.next(List(updatedWinery.docs));
      },
      error => {
        this.setLastError('Editing Doc List', error.message, winery);
      }
    );
    return docs$.asObservable();
  }

  deleteDocList(winery: WineryDetail, data: DocList): Observable<List<DocList>> {
    const docs$: BehaviorSubject<List<DocList>> = new BehaviorSubject<List<DocList>>(List(winery.docs));
    this.apiService.delete(`/wineries/${winery._id}/docs/${data._id}`).subscribe(
      updatedWinery => {
        this.updateWineryInList(updatedWinery);
        docs$.next(List(updatedWinery.docs));
      },
      error => {
        this.setLastError('Removing Doc List', error.message, winery);
      }
    );
    return docs$.asObservable();
  }

  updateWineryDocs(winery: WineryDetail, data: DocList[]): Observable<List<DocList>> {
    const docs$: BehaviorSubject<List<DocList>> = new BehaviorSubject<List<DocList>>(List(data));
    const docIds = data.map(d => d._id);
    this.apiService.put(`/wineries/${winery._id}`, { ...winery, docs: docIds }).subscribe(
      updatedWinery => {
        this.updateWineryInList(updatedWinery);
        docs$.next(List(updatedWinery.docs));
      },
      error => {
        this.setLastError(`Saving ${winery.category}`, error.message, winery);
      }
    );
    return docs$.asObservable();
  }
}
